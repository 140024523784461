import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from '../actions/books.actions';
import { BooksState } from '../app.state';
import { BookModel } from '../models';

const initialState: BooksState = { books: [] };

const reducer = createReducer(
  initialState,
  on(fromActions.BOOKS_CLEAR_ACTION, () => {
    return initialState;
  }),
  on(fromActions.BOOKS_ADD_ACTION, (state, { payload }) => {
    const books = [...state.books, payload];
    return { books };
  }),
  on(fromActions.BOOKS_REMOVE_ACTION, (state, { payload }) => {
    const books = state.books.filter((book: BookModel) => book.id !== payload);
    return { books };
  })
);

export function booksReducer(state: any, action: Action) {
  return reducer(state, action);
}
