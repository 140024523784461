import { Injectable, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppService implements OnDestroy {
  private windowsScroll$: Subscription;

  constructor(private readonly router: Router) {}

  ngOnDestroy(): void {
    this.windowsScroll$.unsubscribe();
  }

  async init(): Promise<void> {
    await Promise.resolve(() => {
      this.windowsScroll();
    });
  }

  private windowsScroll(): void {
    this.windowsScroll$ = this.router.events.subscribe((event: Event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
