import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((module: any) => {
    const applicationRef: any = module.injector.get(ApplicationRef);
    const appComponent: any = applicationRef.components[0];
    if (!environment.production) {
      enableDebugTools(appComponent);
    }
  })
  .catch((err: any) => {
    console.error(err);
  });
