import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BooksState } from '../app.state';
import { BookModel } from '../models';

const booksState = createFeatureSelector<BooksState>('booksState');

export const selectBooks = createSelector(booksState, (state: BooksState) => state.books);

export const selectBooksById = (props: { id: string }) =>
  createSelector(selectBooks, (books: BookModel[]) => books.find((book) => book.id === props.id));

export const selectBooksByISBN = (props: { isbn: string }) =>
  createSelector(selectBooks, (books: BookModel[]) => books.find((book) => book.isbn === props.isbn));

export const selectBooksByTitle = (props: { title: string }) =>
  createSelector(selectBooks, (books: BookModel[]) => books.find((book) => book.title === props.title));
